<template>
    <div class="login-bind-account flex-align">
        <div class="board">
            <ul class="header flex-align">
                <li>
                    <a href="/" rel="noopener noreferrer">
                        <img src="@/assets/images/home/doubleLogo.png" alt />
                    </a>
                </li>
            </ul>
            <h2 class="title">关联智慧研修平台账号</h2>
            <p class="tips">关联后，您可以用银川智慧教育平台账号登录研修平台</p>
            <!-- 表单 -->
            <div class="form-section">
                <!-- 用户名登录 -->
                <el-form v-if="!activeForm" key="ruleForm" :model="ruleForm" status-icon ref="ruleForm"
                    label-width="70px" class="login-form">
                    <el-form-item label="用户名：" prop="username">
                        <el-input type="text" v-model="ruleForm.username" maxlength="11" autocomplete="off"
                            placeholder="请输入手机号码或用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="密码：" prop="password" class="usernameForm-pwd">
                        <el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="请输入登录密码">
                        </el-input>
                    </el-form-item>

                    <el-form-item label prop="type" class="summary" label-width="0px">
                        <el-link type="primary" class="forget" @click="forgetPassword()">忘记密码？
                        </el-link>
                    </el-form-item>
                    <el-form-item class="btn-box" label-width="0">
                        <el-button type="primary" @click="submitForm(ruleForm)" class="login-btn">关联</el-button>
                    </el-form-item>
                    <el-form-item label-width="0px">
                        <p @click="activeForm = 1" class="csp switch">手机验证码登录</p>
                    </el-form-item>
                </el-form>
                <!-- 手机验证码登录 -->
                <el-form v-else key="verifyForm" :rules="rule" :model="verifyForm" status-icon ref="verifyForm"
                    label-width="70px" class="login-form verify-form" hide-required-asterisk>
                    <el-form-item label="手机号：" prop="phone">
                        <el-input type="text" v-model="verifyForm.phone" maxlength="11" autocomplete="off"
                            placeholder="请输入手机号码">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="验证码：" prop="verifyCode" class="el-form-code-item">
                        <el-input v-model="verifyForm.verifyCode" placeholder="请输入手机验证码"></el-input>
                        <el-button class="getVerfiyCode" @click="getCode" v-if="showCode">获取验证码</el-button>
                        <el-button class="getVerfiyCode" v-else>{{`重新发送(${count}s)`}}</el-button>
                    </el-form-item>

                    <el-form-item class="btn-box" label-width="0px">
                        <el-button type="primary" @click="submitVerifyForm('verifyForm')" class="login-btn">
                            关联
                        </el-button>
                    </el-form-item>

                    <el-form-item label-width="0px">
                        <p @click="activeForm = 0" class="csp switch">账号密码登录</p>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data() {
            var checkPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('手机号不能为空'));
                } else if (!/^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/.test(
                        value)) {
                    return callback(new Error('手机号格式错误'));
                } else {
                    return callback();
                }
            };
            var checkCode = (rule, value, callback) => {
                // console.log(value)
                if (!value) {
                    return callback(new Error('验证码不能为空'));
                } else {
                    let params = {
                        phone: this.verifyForm.phone,
                        code: String(value)
                    }
                    this.$Api.Login.checkCode(params).then((resData) => {
                        // console.log(resData)
                        if (resData.data.result) {
                            return callback();
                        } else {
                            this.$message({
                                message: resData.data.msg,
                                type: 'error'
                            });
                            return callback(new Error('验证码错误'));
                        }
                    })
                }
            };
            return {
                ruleForm: {
                    password: "",
                    username: "",
                    type: []
                },
                verifyForm: {
                    phone: "",
                    verifyCode: "",
                },
                dialogVisible: false,
                activeForm: 0,
                rule: {
                    phone: [{
                        required: true,
                        trigger: "blur",
                        validator: checkPhone
                    }],
                    verifyCode: [{
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                        validator: checkCode
                    }],
                },
                showCode: true,
                count: 0,
                timer: "",
                flag: false,
                checkBindInfo: {
                    account: "",
                    uniqueid: "",
                }
            };
        },
        methods: {
            // 获取验证码
            async getCode() {
                if (!/^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/.test(this
                        .verifyForm
                        .phone)) {
                    this.$message({
                        message: '手机号格式错误',
                        type: 'error'
                    });
                    return
                }
                this.countDown();
                let params = {
                    phone: this.verifyForm.phone
                }
                let resData = await this.$Api.Login.getVerifyCode(params)
                // console.log(resData);
                if (resData.code === 200 && resData.msg === "请求成功") {
                    this.$message({
                        message: '验证码发送成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: resData.msg,
                        type: 'error'
                    });
                }
            },
            // 倒计时
            countDown() {
                const TIME_COUNT = 60;
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.showCode = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.showCode = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }
            },
            submitVerifyForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.commitForm();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async commitForm() {
                let params = {
                    phone: this.verifyForm.phone,
                    code: this.verifyForm.verifyCode
                }

                axios({
                        url: `/auth/user/third/bindPhone?type=ycDingDingThird&account=${this.checkBindInfo.account}&uniqueid=${this.checkBindInfo.uniqueid}`,
                        method: "post",
                        headers: {
                            "Content-Type": "application/json; charset=UTF-8"
                        },
                        data: params
                    })
                    .then((resData) => {
                        // console.log(resData.data);
                        if (resData.data.code == 200 && resData.data.data.access_token) {
                            sessionStorage.token = resData.data.data.access_token;
                            this.$notify({
                                title: "成功",
                                message: "绑定成功",
                                type: "success"
                            });
                            let data = resData.data;
                            this.$router.push({
                                path: '/login',
                                query: {
                                    isThirdLogin: true,
                                    data
                                }
                            })
                            // this.userinfo();
                        } else {
                            this.$notify.error({
                                title: "错误",
                                message: resData.data.data.msg
                            });
                        }
                    }).catch(() => {
                        console.log(resData);
                    })
            },
            // 提交登录
            submitForm(form) {
                if (form.username == "") {
                    this.$notify.error({
                        title: "错误",
                        message: "请填写用户名"
                    });
                    return false;
                }
                if (form.password == "") {
                    this.$notify.error({
                        title: "错误",
                        message: "请填写登陆密码"
                    });
                    return false;
                }
                let params = {
                    username: form.username,
                    password: form.password
                };

                axios({
                        url: `/auth/user/third/bindUser?type=ycDingDingThird&account=${this.checkBindInfo.account}&uniqueid=${this.checkBindInfo.uniqueid}`,
                        method: "post",
                        headers: {
                            "Content-Type": "application/json; charset=UTF-8"
                        },
                        data: params
                    })
                    .then((resData) => {
                        // console.log(resData.data);
                        if (resData.data.code == 200 && resData.data.data.access_token) {
                            sessionStorage.token = resData.data.data.access_token;
                            this.$notify({
                                title: "成功",
                                message: "绑定成功",
                                type: "success"
                            });
                            let data = resData.data;
                            this.$router.push({
                                path: '/login',
                                query: {
                                    isThirdLogin: true,
                                    data
                                }
                            })
                            // this.userinfo();
                        } else {
                            this.$notify.error({
                                title: "错误",
                                message: resData.data.data.msg
                            });
                        }
                    }).catch(() => {
                        console.log(resData);
                    })

            },
            async userinfo() {
                let params = {
                    // user: "01",
                };
                let resData = await this.$Api.Home.userInfo(params);
                sessionStorage.userinfo = JSON.stringify(resData.data);
                this.getroles();
            },
            async getroles() {
                // let params = {};
                let resData = await this.$Api.Home.getroles();
                sessionStorage.role = resData.data[0].value;
                sessionStorage.roleId = resData.data[0].id;
                sessionStorage.rolelist = JSON.stringify(resData.data);
                Common.setRole(this)
                this.$router.push("/home");
            },
            //重置输入框内的数值,
            resetForm(formName) {
                this.$nextTick(() => {
                    if (this.$refs[formName]) {
                        this.$refs[formName].resetFields();
                    }
                });
            },
            checkBind() {
                let params = {
                    code: this.$route.query.code,
                    state: this.$route.query.state,
                    session_state: this.$route.query.session_state,
                    path: `https://${window.location.host}/login/bindYcDingDingAccount`
                }

                axios({
                        url: `/auth/user/third/checkBind?type=ycDingDingThird`,
                        method: "post",
                        headers: {
                            "Content-Type": "application/json; charset=UTF-8"
                        },
                        data: params
                    })
                    .then((resData) => {
                        // console.log(resData.data.data);
                        if (!resData.data.data.result) {
                            this.checkBindInfo.account = resData.data.data.data.account
                            this.checkBindInfo.uniqueid = resData.data.data.data.uniqueid
                        } else {
                            sessionStorage.token = resData.data.data.data.access_token;
                            this.$notify({
                                title: "成功",
                                message: "绑定成功",
                                type: "success"
                            });
                            let data = resData.data.data;
                            this.$router.push({
                                path: '/login',
                                query: {
                                    isThirdLogin: true,
                                    data
                                }
                            })
                            // this.userinfo();
                        }
                    }).catch(() => {
                        console.log(resData);
                    })
            },
            // 忘记密码
            forgetPassword() {
                this.$router.push('/login/forgetpwd')
                // 存储是否是从第三方跳转至忘记密码页面
                sessionStorage.setItem('ycForgetFrom', true);
            },
            init() {
                sessionStorage.setItem('loginPath', 'third');
                this.checkBind();
            }

        },
        mounted() {
            this.init();
        },
        activated() {
            console.log(123456)
        },
        created() {},
        deactivated() {
            this.active = 0;
        }
    };
</script>

<style lang="less" scoped>
    .login-bind-account {
        position: relative;
        height: 100vh;
        max-height: 100vh;
        min-width: 1000px;
        background-color: #fff;
        background-image: url("../../assets/images/login/modify-bg.png");
        background-repeat: no-repeat;
        background-position: 50% 25%;
        background-size: cover;
        line-height: 1;

        .header {
            padding: 60px 0 0 165px;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999;
        }

        .board {
            box-sizing: border-box;
            width: 841px;
            max-width: 1600px;
            height: 505px;
            margin: 0 auto;
            border-radius: 10px;
            background-color: rgb(255, 255, 255);
            text-align: center;
            padding: 50px 240px 0;
        }

        .title {
            margin-bottom: 28px;
            font-size: 32px;
            color: #508EF9;
        }

        .tips {
            margin-bottom: 50px;
            font-size: 15px;
            color: #999;
        }

        .form-section {
            flex: 1;
            box-sizing: border-box;
            overflow: hidden;

            .welcome {
                font-size: 30px;
                text-align: center;
                color: #508EF9;
                margin: 40px 0 50px;
            }

            .tabs {
                margin-bottom: 38px;
                color: #B5B5B5;
                font-size: 16px;

                .gap {
                    margin: 0 38px;
                    color: #B5B5B5;
                    vertical-align: 1px;
                }

                .tab {
                    cursor: pointer;
                }

                .active-tab {
                    color: #666;
                }
            }

            .login-form {
                margin: 0 auto;

                /deep/ .el-form-item {
                    margin-bottom: 25px;
                }

                /deep/ .el-form-item__label {
                    color: #999999;
                }

                /deep/ .el-input__inner {
                    border-radius: 5px;
                    border: 1px solid #CDD8EB;
                }

                /deep/ .el-form-item.is-error .el-input__inner,
                /deep/ .el-form-item.is-error .el-input__inner:focus,
                /deep/ .el-form-item.is-error .el-textarea__inner,
                /deep/ .el-form-item.is-error .el-textarea__inner:focus,
                /deep/ .el-message-box__input input.invalid,
                /deep/ .el-message-box__input input.invalid:focus {
                    border-color: #F56C6C !important;
                }

                /deep/ .el-form-code-item {
                    margin-bottom: 67px;

                    .el-form-item__content {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }

                /deep/ .usernameForm-pwd {
                    margin-bottom: 12px;
                }

                .validate-code {
                    margin-left: 27px;

                    /deep/ .s-canvas {
                        height: 40px;
                    }
                }

                .el-button.getVerfiyCode {
                    margin-left: 10px;
                    width: 120px;
                    height: 40px;
                    border: 1px solid #508EF9;
                    border-radius: 2px;
                    color: #508EF9;
                }

                .summary /deep/.el-form-item__content {
                    line-height: 15px;

                    .el-checkbox-group {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .el-link--inner {
                            color: #0496E2;
                        }
                    }
                }

                .summary {
                    margin-bottom: 38px;
                    text-align: right;
                }

                .btn-box {
                    padding: 0 11px;
                }

                .el-button.login-btn {
                    //    margin-top: 26px;
                    border: 1px solid #508EF9;
                    border-radius: 25px;
                    width: 100%;
                    line-height: 40px;
                    height: 40px;
                    padding: 0;

                    font-size: 18px;
                    background-color: #508EF9;
                }

                .switch {
                    font-size: 16px;
                    color: #666;
                    line-height: 16px;

                    &:hover {
                        color: #409EFF;
                        text-decoration: underline;
                    }
                }


            }

            .yc-login {
                text-align: center;
                position: relative;
                margin-bottom: 5px;

                &:hover {
                    color: #508EF9;
                    text-decoration: underline;
                }
            }
        }

    }
</style>